@font-face {
    font-family: 'Mea Culpa';
    src: url('./Mea_Culpa/MeaCulpa-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
  font-family: 'Fleur De Leah';
  src: url('./Fleur_De_Leah/FleurDeLeah-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Dancing Script';
  src: url('./Dancing_Script/DancingScript-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pacifico';
  src: url('./Pacifico/Pacifico-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lora';
  src: url('./Lora/Lora-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Satisfy';
  src: url('./Satisfy/Satisfy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

