.seating-chart-page {
  min-height: 100vh;
  padding: 20px;
  background: #f5f5f5;
  padding-top: 80px;
}

.chart-container {
  margin: 20px auto;
  width: 1200px;
  height: 1800px;
  background: white;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chart-background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  padding: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}

.content-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  transition: all 0.3s ease;
}

.guest-lists {
  display: flex;
  gap: 20px;
  flex: 1.2;
  position: relative;
  transition: all 0.3s ease;
}

/* 显示时的宽度 */
.guest-column {
  flex: 1;
  min-width: 200px;
  transition: min-width 0.3s ease;
}

/* 隐藏桌位图时的布局 */
.content-section.hide-tables {
  justify-content: center;
}

.content-section.hide-tables .guest-column {
  min-width: 270px;
}

.content-section.hide-tables .guest-lists {
  flex: 0 1 35%;
}

.content-section.hide-tables .center-layout-container {
  display: none;
}

/* 显示桌位图时第二列和第四列的偏移 */
.content-section:not(.hide-tables) .guest-column:nth-child(2),
.content-section:not(.hide-tables) .guest-lists:last-child .guest-column:nth-child(2) {
  margin-left: -30px;
}

.center-layout-container {
  flex: 2;
  display: flex;
  justify-content: center;
  margin: 0 10px;
  transition: all 0.3s ease;
}

.chart-title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 20px;
  font-family: var(--title-font);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tables-layout {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.left-columns, .right-columns {
  display: flex;
  gap: 8px;
  width: 40%;
  padding: 0 8px;
}

.tables-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content-wrapper {
  width: 28%;
}

.couple-names {
  text-align: center;
  font-size: 24px;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--couple-names-font);
}

.table-info {
  width: 100%;
  margin-bottom: 8px;
  padding: 6px;
  display: flex;
  align-items: flex-start;
}

.table-info:hover {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.table-info-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 24px;
  padding: 4px 8px;
  line-height: 1.5;
  word-break: break-all;
  color: #333;
}

.table-info-content .table-name {
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
  margin-right: 4px;
}

.table-info-content .guest-name {
  vertical-align: top;
  margin-right: 4px;
  justify-content: center;
  align-items: center;
  height: 100%; 
  text-align: center;
}

.table-info-content:hover {
  background: transparent;
}

.table-info-content:focus {
  background: rgba(255, 255, 255, 0.1);
  outline: none;
  box-shadow: inset 0 0 0 1px rgba(76, 130, 255, 0.5);
}

[contenteditable] {
  outline: none;
  white-space: pre-wrap;
  word-break: break-all;
  line-height: 1.5;
  min-height: 52px;
  display: inline-block;
  vertical-align: middle;
}

.center-layout {
  position: relative;
  border: 1px solid rgba(238, 238, 238, 0.5);
  background-color: transparent;
  transition: background 0.3s ease;
}

.center-layout.show-grid {
  background-image: linear-gradient(0deg, rgba(200, 200, 200, 0.2) 1px, transparent 1px),
    linear-gradient(90deg, rgba(200, 200, 200, 0.2) 1px, transparent 1px);
  background-size: 20px 20px;
}

.table-icon {
  position: absolute;
  user-select: none;
  cursor: move;
}

.table-icon img {
  width: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: contain;
}

.resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #4c82ff;
  border: 1px solid #fff;
  border-radius: 50%;
}

.resize-handle.top-left { top: -5px; left: -5px; cursor: nw-resize; }
.resize-handle.top-right { top: -5px; right: -5px; cursor: ne-resize; }
.resize-handle.bottom-left { bottom: -5px; left: -5px; cursor: sw-resize; }
.resize-handle.bottom-right { bottom: -5px; right: -5px; cursor: se-resize; }

.background-upload {
  margin: 0 10px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px 0;
  background-color: #f5f5f5;
}

.button-container button,
.button-container .upload-label .btn {
  background-color: #f5f5f5;
  border: 1px solid #333;
  color: #333;
  border-radius: 4px;
  padding: 4px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 14px;
  line-height: 1.5;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin: 0;
}

.button-container button:hover,
.button-container .upload-label .btn:hover {
  background-color: #e0e0e0;
}

.button-container i {
  font-size: 12px;
  margin-right: 4px;
}

.upload-container {
  height: 32px;
  display: inline-flex;
  align-items: center;
}

.upload-label {
  display: inline-flex;
  align-items: center;
  height: 100%;
}

.file-name {
  margin-left: 5px;
  font-size: 14px;
}

.remove-file {
  border: none;
  background: none;
  color: #ff4c61;
  font-size: 16px;
  padding: 0 4px;
  cursor: pointer;
}

.remove-file:hover {
  color: #ff2b44;
}

.wedding-date {
  text-align: center;
  font-size: 18px;
  margin: 0;
  margin-bottom: 20px;
  cursor: text;
  padding: 4px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--date-font);
}

.wedding-date:hover {
  background: #f5f5f5;
}

.wedding-date:focus {
  background: #f0f0f0;
  outline: none;
  box-shadow: inset 0 0 0 1px #4c82ff;
}

.table-layout-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  opacity: 1;
}

.table-layout-item.hidden {
  opacity: 0;
  pointer-events: none;
}

.table-layout-name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  pointer-events: none;
  white-space: nowrap;
}

.delete-guest-btn {
  width: 2px;
  height: 2px;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s;
  font-size: 26px;
  margin-left: 0px;
}

.delete-guest-btn:hover {
  opacity: 1;
}

.delete-guest-btn i {
  font-size: 16px;
  pointer-events: auto;
}

.draggable-image {
  position: absolute;
  border: 1px solid transparent;
}

.draggable-image:hover {
  border-color: #4c82ff;
}

.draggable-image .resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #4c82ff;
  border: 1px solid #fff;
  border-radius: 50%;
  right: -5px;
  bottom: -5px;
  cursor: se-resize;
}

.delete-image-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ff4c61;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s;
}

.draggable-image:hover .delete-image-btn {
  opacity: 1;
}

.delete-image-btn:hover {
  background: #ff2b44;
} 

.tables-container img[src*="stage"] {
  background-color: transparent !important;
  border: 3px solid black !important;
}

.table-content[style*="stage"] {
  background-color: transparent !important;
  border: 3px solid black !important;
  background-blend-mode: normal !important;
}

.table-content[style*="stage"] div {
  color: black !important;
}

img[src*="stage"] {
  background-color: transparent !important;
  border: 2px solid black !important;
}

.chart-tools {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.chart-tools button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.table-content-wrapper {
  padding: 6px;
}

.table-name {
  margin-bottom: 8px;
  font-family: var(--table-font);
}

.guest-list {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 3px;
}

.guest-item {
  font-size: 15px;
}

/* 隐藏桌位图时的宾客列表样式 */
.content-section.hide-tables .guest-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px 15px;
  width: 100%;
  padding: 0 5px;
}

.content-section.hide-tables .guest-item {
  text-align: left;
  padding: 2px 0;
  font-size: 14px;
}

.content-section.hide-tables .table-name {
  text-align: center;
  width: 100%;
  display: block;
}

/* 显示桌位图时的宾客列表样式 */
.content-section:not(.hide-tables) .guest-list {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.guest-list {
  margin-top: 5px;
}

.table-name {
  margin-bottom: 8px;
}

.style-sidebar {
  position: fixed;
  right: -300px;
  top: 150px;
  width: 300px;
  height: calc(100vh - 150px);
  background: white;
  box-shadow: -2px 0 10px rgba(0,0,0,0.1);
  transition: right 0.3s ease;
  z-index: 1000;
  padding: 20px;
}

.style-sidebar.open {
  right: 0;
}

.style-sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.style-sidebar-close {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
}

.style-sidebar-close:hover {
  color: #666;
}

.style-section {
  margin-bottom: 25px;
}

.section-title {
  font-size: 16px;
  color: #333;
  margin-bottom: 15px;
  padding: 0 15px;
  font-weight: 600;
}

.style-options {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.style-option {
  padding: 12px;
  border: 1px solid #eee;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.style-option:hover {
  background-color: #f5f5f5;
}

.style-option.selected {
  border-color: #007bff;
  background-color: #e7f1ff;
}

.style-option h4 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.toggle-tables-btn {
  width: 100%;
}

.chart-container.paper-style {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  gap: 40px;
  padding: 40px;
  background: #f5f5f5;
  box-shadow: none;
  margin: 0;
  justify-content: start;
  overflow: visible;
}

.paper-card {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  height: fit-content;
  width: 360px;
}

.paper-style .header-section {
  margin: 0;
  padding: 15px;
  min-width: 240px;
  max-width: fit-content;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.paper-style .header-card {
  min-width: 240px;
  max-width: fit-content;
  height: 200px !important;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.paper-style .chart-title {
  min-width: 240px;
  max-width: fit-content;
  font-size: 20px;
  margin-bottom: 30px;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0;
}

.paper-style .couple-names {
  font-size: 18px;
  margin-bottom: 30px;
}

.paper-style .wedding-date {
  font-size: 12px;
}

.paper-style .table-info {
  width: 180px;
  height: 300px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.paper-style .guest-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  padding: 0;
}

.paper-style .guest-item {
  text-align: center;
  font-size: 12px;
  line-height: 1.2;
}

.paper-style .table-name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.style-sidebar .font-options {
  padding: 10px;
}

.font-group {
  margin-bottom: 15px;
}

.font-group label {
  display: block;
  margin-bottom: 5px;
  color: #666;
  font-size: 14px;
}

.font-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
}

.font-group select:focus {
  outline: none;
  border-color: #007bff;
}

/* 为每个字体选项添加预览样式 */
.font-group select option[value="Dancing Script"] {
  font-family: "Dancing Script", cursive;
}

.font-group select option[value="Playfair Display"] {
  font-family: "Playfair Display", serif;
}

.font-group select option[value="Lora"] {
  font-family: "Lora", serif;
}

.font-group select option[value="Pacifico"] {
  font-family: "Pacifico", cursive;
}

.font-group select option[value="Satisfy"] {
  font-family: "Satisfy", cursive;
}

.font-group select option[value="Mea Culpa"] {
  font-family: "Mea Culpa", cursive;
}

.font-group select option[value="Arial"] {
  font-family: "Arial", sans-serif;
}

/* 添加恢复初始化设置按钮样式 */
.reset-style-btn {
  margin-top: 5px;
  margin-bottom: 15px;
  width: 100%;
  border-color: #6c757d;
  color: #6c757d;
  font-size: 14px;
  transition: all 0.2s ease;
}

.reset-style-btn:hover {
  background-color: #6c757d;
  color: white;
}