.table-layout-page {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.tables-container-wrapper {
    width: 100%;
    height: calc(100% - 80px);
    margin-top: 80px;
    overflow: auto;
    padding: 20px;
}

.tables-container {
    position: relative;
    background-color: #f0f0f0;
    background-image: linear-gradient(0deg, rgba(200, 200, 200, 0.5) 1px, transparent 1px),
        linear-gradient(90deg, rgba(200, 200, 200, 0.5) 1px, transparent 1px);
    background-size: 20px 20px;
    border-color: #ff637d;
    box-sizing: border-box;
    margin: 0 auto;
    transform-origin: center center;
}

.button-container {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: white;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.save-button,
.reset-button {
    margin: 0 10px;
}

.save-button {
    background-color: #aac2faf3 !important;
    border-color: #4c82ff !important;
}

.reset-button {
    color: #000000 !important;
    background-color: #fff !important;
    border-color: #4c82ff !important;
}

.back-button {
    color: #000000 !important;
    background-color: #fff !important;
    border-color: #4c82ff !important;
    font-size: 12px !important;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin: 0 8px 0 20px;
}

.back-button i {
    font-size: 12px !important;
}

.back-button:hover,
.back-button:focus {
    background-color: #4c82ff !important;
    color: #fff !important;
}

.save-button:hover,
.reset-button:hover,
.save-button:focus,
.reset-button:focus {
    background-color: #ff4c61 !important;
    border-color: #ff4c61 !important;
}

.size-inputs {
    display: flex;
    gap: 12px;
    align-items: flex-end;
    margin: 0 8px;
}

.size-inputs label {
    display: flex;
    align-items: center;
    gap: 2px;
    white-space: nowrap;
}

.size-inputs label span.unit {
    margin-right: 20px;
}

.size-input {
    width: 80px;
    margin: 0 2px;
}

.table-layout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
}

.search-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 1rem;
    width: 300px;
    margin: 0 auto 1rem;
}

.search-bar input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-guest-list {
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 0px;
    top: 100%;
    left: 0;
    z-index: 1000;
}

.guest-item {
    padding: 0.5rem;
    cursor: pointer;
}

.guest-item:hover {
    background-color: #f0f0f0;
}

.table-guest-list {
  position: absolute;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid #4c82ff;
  z-index: 1000;
  min-width: 300px;
}

.table-guest-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.table-guest-list-header h3 {
  margin: 0;
  color: #4c82ff;
}

.table-guest-list-header button {
  background: none;
  border: none;
  color: #666;
  font-size: 20px;
  cursor: pointer;
  padding: 0 5px;
}

.table-guest-list-content {
  max-height: 300px;
  overflow-y: auto;
}

.table-guest-item {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.table-guest-item:last-child {
  border-bottom: none;
}

.scale-indicator {
    position: fixed;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    cursor: move;
    user-select: none;
    width: 60px;
    height: 50px;
}

.scale-line {
    position: relative;
    width: 40px !important;
    height: 2px;
    background-color: rgba(51, 51, 51, 0.8);
    margin-bottom: 5px;
    transform: none !important;
}

.scale-line::before,
.scale-line::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 6px;
    background-color: #333;
    bottom: 0;
}

.scale-line::before {
    left: 0;
}

.scale-line::after {
    right: 0;
}

.scale-line span {
    position: absolute;
    left: 50%;
    width: 2px;
    height: 4px;
    background-color: #333;
    bottom: 0;
    transform: translateX(-50%);
}

.scale-ticks {
    position: absolute;
    width: 100%;
    height: 100%;
}

.scale-tick {
    position: absolute;
    width: 2px;
    height: 4px;
    background-color: #333;
    bottom: 0;
}

.scale-tick:nth-child(1) { left: 20px; }

.draggable-image {
  position: absolute;
  border: 1px solid transparent;
  cursor: move;
}

.draggable-image:hover {
  border-color: #4c82ff;
}

.draggable-image .resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #4c82ff;
  border: 1px solid #fff;
  border-radius: 50%;
  right: -5px;
  bottom: -5px;
  cursor: se-resize;
}

.delete-image-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ff4c61;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  opacity: 0;
  transition: opacity 0.2s;
}

.draggable-image:hover .delete-image-btn {
  opacity: 1;
}

.delete-image-btn:hover {
  background: #ff2b44;
}

.couple-names-input {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.couple-names-input label {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.couple-names-input input, .couple-names-input select {
  padding: 5px;
  border-radius: 4px;
}

.couple-names-display {
  padding: 10px;
  background-color: transparent;
  user-select: none;
  white-space: nowrap;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: grab;
  border: 1px dashed transparent;
  transition: border-color 0.2s;
  touch-action: none;
}

.couple-names-display:hover {
  border-color: #4c82ff;
}

.couple-names-display:active {
  cursor: grabbing;
  border-color: #4c82ff;
}

/* 添加边栏样式 */
.drawer-content {
  width: 300px;
  padding: 20px;
}

.drawer-section {
  margin-bottom: 25px;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}

.drawer-section h4 {
  margin-bottom: 15px;
  color: #333;
  font-size: 16px;
}

.drawer-input-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.drawer-input-group label {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
}

.drawer-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.drawer-input-small {
  width: 80px;
}

.drawer-select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.drawer-button {
  width: 100%;
  margin-top: 10px;
}

.edit-button {
  margin-left: 10px;
  background-color: #4c82ff;
  border-color: #4c82ff;
}

/* 为每个字体选项添加预览样式 */
.drawer-select option[value="Dancing Script"] {
  font-family: "Dancing Script", cursive;
}

.drawer-select option[value="Playfair Display"] {
  font-family: "Playfair Display", serif;
}

.drawer-select option[value="Lora"] {
  font-family: "Lora", serif;
}

.drawer-select option[value="Pacifico"] {
  font-family: "Pacifico", cursive;
}

.drawer-select option[value="Satisfy"] {
  font-family: "Satisfy", cursive;
}

.drawer-select option[value="Mea Culpa"] {
  font-family: "Mea Culpa", cursive;
}

.drawer-select option[value="Fleur De Leah"] {
  font-family: "Fleur De Leah", cursive;
}

/* 添加婚礼日期样式 */
.wedding-date-display {
  padding: 10px;
  background-color: transparent;
  user-select: none;
  white-space: nowrap;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: grab;
  border: 1px dashed transparent;
  transition: border-color 0.2s;
  touch-action: none;
}

.wedding-date-display:hover {
  border-color: #4c82ff;
}

.wedding-date-display:active {
  cursor: grabbing;
  border-color: #4c82ff;
}

.wedding-date-display.published {
  pointer-events: none;
  border: none;
}

.table-edit-dialog {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  animation: fadeIn 0.2s ease-in-out;
  position: fixed;
  left: 80px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1100;
  width: 280px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 24px;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-50%) translateX(-20px); }
  to { opacity: 1; transform: translateY(-50%) translateX(0); }
}

.table-edit-dialog h4 {
  margin: 0;
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
  font-weight: 600;
}

.table-edit-dialog .close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 20px;
  color: #666;
  cursor: pointer;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.table-edit-dialog .close-button:hover {
  background-color: #f0f0f0;
  color: #333;
}

.table-edit-dialog .form-group {
  margin-bottom: 16px;
}

.table-edit-dialog label {
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 6px;
  font-weight: 500;
}

.table-edit-dialog input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: #fafafa;
  transition: all 0.2s;
}

.table-edit-dialog input:focus {
  outline: none;
  border-color: #4c82ff;
  background-color: white;
  box-shadow: 0 0 0 2px rgba(76, 130, 255, 0.1);
}

.table-edit-dialog .input-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.table-edit-dialog .button-group {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.table-edit-dialog button {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.table-edit-dialog .cancel-button {
  background-color: white;
  border: 1px solid #ddd;
  color: #555;
}

.table-edit-dialog .cancel-button:hover {
  background-color: #f5f5f5;
}

.table-edit-dialog .save-button {
  background-color: #4c82ff;
  border: none;
  color: white;
}

.table-edit-dialog .save-button:hover {
  background-color: #3a70e3;
}

/* 旋转控制器样式 */
.rotation-handle {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #4c82ff;
  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
  z-index: 100;
}

.rotation-handle:active {
  cursor: grabbing;
}

/* 确保旋转控制器始终显示在顶层 */
.draggable-table {
  position: relative;
}

.flip-controls {
  margin-top: 15px;
}

.checkbox-group {
  display: inline-block;
  margin-right: 30px;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 8px;
}

/* 确保字体选择器容器样式一致 */
.font-selector-container {
  width: 100%;
}

/* 确保字体预览在WeddingTableLayoutPage中也能正常工作 */
.font-selector {
  position: relative;
  width: 100%;
}

.drawer-input-group .font-selector-selected {
  width: 100%;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 30px;
  overflow: hidden;
}

/* 添加下拉箭头样式 */
.dropdown-arrow {
  font-size: 10px;
  color: #666;
}

/* 添加字体预览样式 */
.font-preview {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 添加下拉选项容器样式 */
.font-selector-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 100;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* 添加选项样式 */
.font-option {
  padding: 8px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.font-option:hover {
  background-color: #f5f5f5;
}

.font-option.selected {
  background-color: #e0e0e0;
}

/* 修改桌子样式网格布局 */
.table-style-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 8px;
  max-height: 160px; /* 限制高度为两行 */
  overflow-y: auto;
  padding-right: 5px;
}

/* 美化滚动条 */
.table-style-grid::-webkit-scrollbar {
  width: 6px;
}

.table-style-grid::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.table-style-grid::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

.table-style-grid::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.table-style-image-container {
  width: 100%;
  aspect-ratio: 1/1;
  border: 2px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.table-style-image-container.selected {
  border-color: #4c82ff;
  background-color: rgba(76, 130, 255, 0.1);
}

.table-style-image {
  max-width: 100%;
  max-height: 100%;
}