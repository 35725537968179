.table-layout-page {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.tables-container-wrapper {
  width: 100%;
  height: calc(100% - 80px);
  margin-top: 80px;
  overflow: auto;
  padding: 20px;
}

.table-layout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
}

.button-container {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: white;
  z-index: 1001;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.search-bar {
  position: relative;
  width: 300px;
  margin: 0 20px;
}

.search-bar input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #4c82ff;
  border-radius: 4px;
}

.search-guest-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #4c82ff;
  border-radius: 4px;
  margin-top: 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1001;
}

.scale-indicator {
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: move;
  user-select: none;
  width: 60px;
  height: 50px;
}

.scale-line {
  position: relative;
  width: 40px !important;
  height: 2px;
  background-color: rgba(51, 51, 51, 0.8);
  margin-bottom: 5px;
  transform: none !important;
}

.scale-line::before,
.scale-line::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 6px;
  background-color: #333;
  bottom: 0;
}

.scale-line::before {
  left: 0;
}

.scale-line::after {
  right: 0;
}

.scale-line span {
  position: absolute;
  left: 50%;
  width: 2px;
  height: 4px;
  background-color: #333;
  bottom: 0;
  transform: translateX(-50%);
}

.save-button,
.reset-button {
  margin-right: 1rem;
}

.reset-button {
  background-color: #fff !important;
}

.back-button {
  color: #000000 !important;
  background-color: #fff !important;
  border-color: #4c82ff !important;
  padding: 6px 16px;
  font-size: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.back-button:hover,
.back-button:focus {
  background-color: #4c82ff !important;
  color: #fff !important;
}

.back-button i {
  font-size: 16px;
}

.save-button:hover,
.reset-button:hover,
.save-button:focus,
.reset-button:focus {
  background-color: #ff4c61 !important;
  border-color: #ff4c61 !important;
}

.size-input {
  width: 80px;
}

.tablemates {
font-size: 0.9em;
color: #666;
margin-top: 4px;
}

.table-guest-list {
  position: absolute;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: none;
  border: 2px solid #4c82ff;
  z-index: 1000;
  min-width: 300px;
}

.table-guest-list-header {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 15px;
border-bottom: 1px solid #eee;
padding-bottom: 10px;
}

.table-guest-list-header h3 {
margin: 0;
color: #4c82ff;
}

.table-guest-list-header button {
border: none;
background: none;
font-size: 24px;
cursor: pointer;
color: #4c82ff;
}

.table-guest-list-header button:hover {
color: #2b5bdb;
}

.table-guest-list-content {
max-height: 400px;
overflow-y: auto;
}

.table-guest-item {
padding: 10px;
border-bottom: 1px solid #eee;
color: #333;
}

.table-guest-item:last-child {
border-bottom: none;
}


.published-page {
  width: 100%;
  height: 100vh;
  padding-top: 64px;
  overflow: hidden;
}

.published-page .button-container {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  z-index: 1000;
  background: white;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.published-page .search-bar {
  position: relative;
  width: 300px;
}

.published-page .back-button {
  position: relative;
  left: 0;
}

.published-page .tables-container-wrapper {
  width: 100%;
  height: calc(100vh - 140px);
  padding-top: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 10px;
  scroll-behavior: smooth;
}

.published-page .tables-container {
  position: relative;
  background: none;
  transform-origin: center center;
  margin: 0 auto;
  max-width: none;
  overflow: visible;
  padding: 20px 50px;
  width: var(--display-width) !important;
  height: auto !important;
}

.published-page .search-bar input {
  width: 100%;
  max-width: none;
  font-size: 12px;
  -webkit-text-size-adjust: 100%;
  touch-action: manipulation;
}

.published-page .click-hint {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(76, 130, 255, 0.9);
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  z-index: 1000;
  display: flex;
  align-items: center;
  gap: 5px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  animation: fadeInOut 3s ease-in-out infinite;
  min-width: 200px;
}

.published-page .click-hint i {
  font-size: 16px;
}

@keyframes fadeInOut {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

@media (max-width: 768px) {
  .published-page .button-container {
    padding: 10px 5px;
  }

  .published-page .back-button {
    display: none;
  }

  .published-page .search-bar {
    width: 100%;
  }

  .published-page .search-bar input {
    width: 100%;
    max-width: none;
  }

  .published-page .tables-container-wrapper {
    padding: 60px 0 0;
    margin-top: 10px;
    height: calc(100vh - 84px);
    overflow: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .published-page .tables-container {
    position: relative;
    transform-origin: center center;
    margin-left: calc(50vw - 50%);
    padding: 20px;
    min-width: fit-content;
  }

  .scale-indicator {
    transform: scale(0.8);
  }

  .table-guest-list {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 90% !important;
    max-width: 90% !important;
    max-height: 80vh !important;
    overflow-y: auto !important;
    margin: 0 !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2) !important;
    z-index: 2000 !important;
  }

  .published-page .click-hint {
    bottom: 40px;
    font-size: 12px;
    padding: 6px 12px;
    min-width: 240px;
  }
}

/* 隐藏 MUI Toolbar 的空间 */
.published-page + .MuiToolbar-root {
display: none;
}

.table-content[style*="stage"] div {
  color: black !important;
}

.published-image {
  pointer-events: none;
  z-index: -10;
}

.published-image img {
  user-select: none;
}

/* 确保桌子显示在图片上方 */
.table-content {
  z-index: 2;
  position: relative;
}

/* 确保拖动时桌子仍然在上层 */
.draggable-table {
  z-index: 2;
  position: relative;
}

/* 修改发布页面新人姓名样式 */
.couple-names-display.published {
  background-color: transparent; /* 移除背景色 */
  padding: 10px;
  user-select: none;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  box-shadow: none; /* 移除阴影 */
  pointer-events: none; /* 在发布页面不可交互 */
}

/* 修改发布页面婚礼日期样式 */
.wedding-date-display.published {
  background-color: transparent; /* 移除背景色 */
  padding: 10px;
  user-select: none;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  box-shadow: none; /* 移除阴影 */
  pointer-events: none; /* 确保不可交互 */
  z-index: 10; /* 确保在桌子上方 */
}