.table-container {
    display: flex;
    height: calc(100vh - 160px); 
    padding: 15px;
    gap: 20px;  
    width: 100%;
}

.list-section {
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.list-section:first-child {
    flex: 0 0 30%; 
}

.list-section:last-child {
    flex: 0 0 70%;
}

.table-item {
    width: 100%;
    background-color: #f8f9fa;  
    border: 1px solid #dee2e6;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    width: 100%;
    border: 1px solid #dee2e6;
    border-radius: 5px;
}

.table-header.overflow {
    border-color: #ff637d;  /* 红色边框 */
    border-width: 2px;      /* 加粗边框 */
}

.table-content {
    padding: 0 15px 15px;
    display: none;
}

.list-container {
    height: auto; 
    min-height: 100px; 
    max-height: calc(100vh - 200px); 
    overflow-y: auto;
    padding: 15px;
}

.table-content.show {
    display: block;
}

.expand-btn {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.expand-btn.expanded {
    transform: rotate(180deg);
}

.guest-list {
    height: auto; 
    min-height: 10px; 
    max-height: calc(100vh - 200px);  
    overflow-y: auto;
    border: 1px solid #4c82ff;
    border-radius: 5px;
    padding: 10px;
}

.list-header {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
}

.add-btn {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #4c82ff;
    border: 2px solid #4c82ff;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.add-btn:hover {
    background-color: #4c82ff;
    color: white;
}

.add-btn::before {
    content: '+';
    font-size: 20px;
    font-weight: bold;
}

.list-item {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #4c82ff;
    margin-bottom: 8px;
    padding: 6px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    position: relative;
    z-index: 1;
}

.list-name {
    font-size: 1rem;
    color: #343a40;
}

.list-count {
    font-size: 1rem;
    color: #6c757d;
    margin-left: 10px;
    background-color: #e9ecef;
    padding: 5px 10px;
    border-radius: 5px;
}

.empty-list {
    font-size: 1.2rem;
    color: #6c757d;
    text-align: center;
    margin-top: 50%;
}

/* create user modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    z-index: 9999;
}

.modal-dialog {
    margin: 0 !important;
    display: flex !important;
    align-items: center;
    height: 100%;
}

.modal-content {
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: auto;
    z-index: 10000;
    position: relative;
}

.modal-content h2 {
    margin-top: 0;
}

.modal input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.modal input:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px #4c82ff;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
}

.save-btn {
    background-color: #4c82ff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.cancel-btn {
    background-color: #ccc;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.table-list {
    height: auto; 
    min-height: 100px; 
    max-height: calc(100vh - 200px);  
    overflow-y: auto;
    border: 1px solid #4c82ff;
    border-radius: 5px;
    padding: 10px;
}

.secondary-navbar {
    padding: 20px;
    height: 80px;
    display: flex;
    align-items: center;
}

.secondary-navbar .d-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.secondary-navbar .btn-outline-primary {
    font-size: 14px;
    padding: 8px 15px;
    color: #4c82ff;
    border-color: #4c82ff;
    white-space: nowrap;
    flex-shrink: 0;
}

.secondary-navbar .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #4c82ff;
    border-color: #4c82ff;
}

.table-style-images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 15px;
    max-height: 300px;
    overflow-y: auto;
}

.table-style-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
}

.table-style-image-container.selected {
    border-color: #007bff;
    background-color: rgba(0, 123, 255, 0.1);
}

.table-style-image-container img {
    max-width: 100%;
    max-height: 60px;
    object-fit: contain;
}

.table-style-image-container span {
    margin-top: 5px;
    font-size: 12px;
    text-align: center;
}

.qrcode-container {
    position: absolute;
    z-index: 10;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 8px;
    margin-top: 10px;
}

.close-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    cursor: pointer;
}

@media (max-width: 768px) {
    .table-container {
        flex-direction: column;
        height: auto;
    }
    
    .list-section {
        height: 50vh;
        margin-bottom: 15px;
    }
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
}

.modal-header .btn-close {
    padding: 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    opacity: .5;
    cursor: pointer;
}

.modal-header .btn-close:hover {
    opacity: .75;
}

.remove-guest-btn {
    width: 24px;
    height: 24px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    color: #000000;
    opacity: 0.6;
    transition: opacity 0.2s;
}

.remove-guest-btn:hover {
    opacity: 1;
    background-color: transparent;
    color: #dc3545;
}

.remove-guest-btn i {
    font-size: 16px;
}

.dimension-input-container {
    display: flex;
    flex-direction: column;
}

.dimension-input-container label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.dimension-input-container input {
    width: 100px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.d-flex button {
    margin: 0 5px;
}

.d-flex button:hover {
    background-color: #4c82ff !important;
    color: white !important;
}

.rotation-input-container {
    margin: 10px 0;
    padding: 10px;
    border-top: 1px solid #eee;
}

.rotation-input-container label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.rotation-input-container input {
    width: 100px;
    padding: 5px;
    margin-left: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.qrcode-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.download-qr-btn {
    margin-top: 10px;
}
