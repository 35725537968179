.list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    margin-bottom: 8px;
    background-color: white;
    border: 1px solid #dee2e6;
    border-radius: 4px;
}

.remove-guest-btn {
    width: 24px;
    height: 24px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: #dc3545;
    cursor: pointer;
    font-size: 18px;
}

.remove-guest-btn:hover {
    color: #c82333;
}

.remove-guest-btn::after {
    content: "×";
    line-height: 1;
}