.context-menu {
    z-index: 1000;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.context-menu-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.context-menu-item {
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap;
}

.context-menu-item:hover {
    background-color: #f7f7f7;
}