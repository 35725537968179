.table-item {
    width: 100%;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    width: 100%;
}

.table-name {
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

.table-count {
    margin-left: 10px;
    padding: 2px 8px;
    background-color: #e9ecef;
    border-radius: 4px;
    font-size: 14px;
    color: #6c757d;
}

.table-content {
    padding: 0 15px 15px;
    display: none;
}

.table-content.show {
    display: block;
}

.expand-btn {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.expand-btn.expanded {
    transform: rotate(180deg);
}